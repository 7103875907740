import './index.css';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';

//components
import MenteeCard from '../../components/MenteeCard';
import Pagination from '../../components/Pagination';
import ModalMentorList from '../../components/ModalMentorList';

//actions
import { mentees, menteesCleanup } from './../../store/actions/mentees';

//hooks
import useQuery from '../../hooks/useQuery';

const Mentees = () => {
    const [data, setData] = useState([]);
    const [meta, setMeta] = useState(null);
    const [page, setPage] = useState(1);
    const [mentee, setMentee] = useState('');
    const [openMentorList, setOpenMentorList] = useState(false);
    const location = useLocation();
    const getMenteesState = useSelector(s => s.mentees);
    const dispatch = useDispatch();
    const query = useQuery();

    useEffect(()=> {
        dispatch(mentees({page}));
    }, [page]);

    useEffect(()=> {
        const newPage = query.get('page')
        if (newPage) setPage(query.get('page'));
    }, [location])

    useEffect(() => {
        if (getMenteesState.isSuccessful) {
            setData(getMenteesState.data.data);
            setMeta(getMenteesState.data.meta);
            dispatch(menteesCleanup());
        } else if (getMenteesState.error) {
            message.error(getMenteesState.error)
            dispatch(menteesCleanup());
        }
    }, [getMenteesState])


    const matchMentee = mentee => {
        setMentee(mentee);
        setOpenMentorList(true)
    };

    const toggle = () => setOpenMentorList(!openMentorList);



    return (
        <>
            <div className="mentees-page container">
                <h1>Mentees</h1>
                {
                    data.map(mentee => (
                        <MenteeCard mentee={mentee} key={mentee?.uuid} matchMentor={matchMentee} />
                    ))
                }
                {   meta &&
                    <Pagination
                        url={location.pathname}
                        current={page}
                        total={meta.total * meta.limit}
                        pageSize={meta.limit}
                    />
                }
            </div>
            <ModalMentorList isOpen={openMentorList} mentee={mentee} toggle={toggle} />
        </>
        
    )
};

export default Mentees;
