import AxiosCall from '../../../utils/axios'
import ErrorHandler from '../../../utils/error-handler';
import * as types from '../../action-types';

export const uploadMentorsStart = () => ({
    type: types.UPLOAD_MENTORS_START,
  });
  
  export const uploadMentorsSuccess = payload => ({
    type: types.UPLOAD_MENTORS_SUCCESS,
    payload,
  });
  
  export const uploadMentorsFail = payload => ({
    type: types.UPLOAD_MENTORS_FAIL,
    payload,
  });
  
  export const uploadMentorsCleanup = () => ({
    type: types.UPLOAD_MENTORS_CLEANUP,
  });
  
  export const uploadMentors = payload => async dispatch => {
    try {
        const form = new FormData();

        for (let key in payload) {
            form.append(key, payload[key]);
        }
        
      dispatch(uploadMentorsStart());
      const requestObj = {
        path: 'users/mentors',
        method: 'POST',
        data: form,
        contentType: 'multipart/form-data'
      };
      
      const { data } = await AxiosCall(requestObj);
      dispatch(uploadMentorsSuccess(data));
    } catch (err) {
      const error = ErrorHandler(err);
      dispatch(uploadMentorsFail(error));
    }
  };
  