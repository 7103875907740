import './index.css';
import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout, Drawer } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

//components
import HeaderMenu from '../Header/index';
import SideMenu from '../Sider/index';


const AdminLayout = () => {
    const { Content, Sider, Header } = Layout;
    const [width, setWidth] = useState(window.innerWidth);
    const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
    const [showDrawer, setShowDrawer] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setCollapsed(window.innerWidth < 768);
            setWidth(window.innerWidth);
        });
    }, []);

    return (
        <Layout>
            <Header>
                <HeaderMenu />
                {window.innerWidth < 768 ? (
                    <div className='ms-5'>
                        {collapsed ? (
                            <MenuUnfoldOutlined
                                className='mmc-text-primary icon-menu-fold'
                                onClick={() => {
                                    setShowDrawer(true);
                                }}
                            /> 
                        ) : (
                            <MenuFoldOutlined
                                className='mmc-text-primary icon-menu-fold'
                                onClick={() => {
                                    setShowDrawer(false);
                                }}
                            />
                        )}
                    </div>
                ) : null}
                <Drawer
                    placement="left"
                    className="menu-drawer"
                    closable={true}
                    onClose={() => setShowDrawer(false)}
                    visible={showDrawer}
                >
                    <SideMenu />
                </Drawer>
            </Header>
            <Layout>
                <Sider 
                    breakpoint='md'
                    collapsible={true}
                    collapsed={collapsed}
                    onCollapse={()=>setCollapsed(!collapsed)}
                    collapsedWidth={width < 768 ? 0 : '100px'}
                    // trigger={null}
                >
                    <SideMenu />
                </Sider>
                <Content>
                    <Outlet className="content-container" />
                </Content>
            </Layout>
        </Layout>
    );
};

export default AdminLayout;
