import './index.css';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Card } from 'reactstrap';
import Pagination from '../../components/Pagination';

//actions
import { getCycles, getCyclesCleanup } from '../../store/actions/get-cycles';

//components
import CycleCard from '../../components/CycleCard';

//svgs
import { ReactComponent as Plus } from '../../svg/plus.svg';
import AddCycleCard from '../../components/AddCycleCard';

const Cycles = () => {
    const [cycles, setCycles] = useState([]);
    const [newModal, setNewModal] = useState(false);
    const [cycle, setCycle] = useState(null);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const cyclesState = useSelector(s => s.getCycles);
    const limit = 11;

    useEffect(()=> {
        dispatch(getCycles());
    }, []);

    useEffect(() => {
        if (cyclesState.isSuccessful) {
            setCycles(cyclesState.data);
            setData(cyclesState.data.slice(0, limit));
            dispatch(getCyclesCleanup());
        } else if (cyclesState.error) {
            dispatch(getCyclesCleanup());
        }
    }, [cyclesState]);

    const editCycle = (cycle) => {
        setCycle(cycle);
        setNewModal(true);
    }

    const closeModal = () => {
        setNewModal(false);
        setCycle(null);
    }

    const toggleModal = () => {
        setNewModal(!newModal);
        setCycle(null);
    }

    const onPaginate = (page) => {
        setCurrentPage(page);
        const start = limit * (page-1);
        const end = start + limit;
        const newData = cycles.slice(limit * (page-1), end);
        setData(newData);
    }

    return (
        <div className='cycles-page container'>
            <h1>Mentorship Cycles Management</h1>
            <Row>
                <div className='add-cycle col-md-3' onClick={()=>setNewModal(true)}>
                    <Card>
                        <Plus />
                        <p>Add new Cycle</p>
                    </Card>
                </div>
                {
                    data.map((cycle) => (
                        <CycleCard key={cycle.uuid} cycle={cycle} onClick={()=>editCycle(cycle)} />
                        
                    ))
                }
            </Row>
            <div className='mt-5'>
                <Pagination
                    current={currentPage}
                    onPaginate={onPaginate}
                    total={cycles.length}
                    pageSize={limit}
                />
            </div>
            <AddCycleCard newModal={newModal} data={cycle} toggle={toggleModal} closeModal={closeModal} uuid={cycle?.uuid} />
        </div>
    );
};

export default Cycles;
