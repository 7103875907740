import './index.css';
import { InputGroup, Input, InputGroupText } from 'reactstrap';
import { SearchOutlined } from '@ant-design/icons';

//svgs
import { ReactComponent as Logo } from '../../svg/Logo.svg';
import { ReactComponent as Notification } from '../../svg/Notification.svg';

//images
import userImg from '../../assets/images/user.png';


const Header = ({user}) => {
    return (
        <div className='admin-header'>
            <Logo title='logo' />
            <div className='rightSide'>
                <InputGroup className='input-group'>
                    <InputGroupText className='input-group-text'>
                        <SearchOutlined />
                    </InputGroupText>
                    <Input className='input-search' type='text' placeholder='Search' />
                </InputGroup>
                <Notification />
                <img src={user ? user.avatar : userImg} alt='user' />
            </div>
        </div>
    );
};

export default Header;
