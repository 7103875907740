import AxiosCall from '../../../utils/axios'
import ErrorHandler from '../../../utils/error-handler';
import * as types from '../../action-types';

export const editCycleStart = () => ({
    type: types.EDIT_CYCLE_START,
  });
  
  export const editCycleSuccess = payload => ({
    type: types.EDIT_CYCLE_SUCCESS,
    payload,
  });
  
  export const editCycleFail = payload => ({
    type: types.EDIT_CYCLE_FAIL,
    payload,
  });
  
  export const editCycleCleanup = () => ({
    type: types.EDIT_CYCLE_CLEANUP,
  });
  
  export const editCycle = payload => async dispatch => {
      const { uuid, data } = payload
    try {
      dispatch(editCycleStart());
      const requestObj = {
        path: `cycles/${uuid}`,
        method: 'PATCH',
        data
      };
      
      const { result } = await AxiosCall(requestObj);
      dispatch(editCycleSuccess(result));
    } catch (err) {
      const error = ErrorHandler(err);
      dispatch(editCycleFail(error));
    }
  };
  