export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_CLEANUP = 'LOGIN_CLEANUP';

export const FETCH_MENTORS_START = 'FETCH_MENTORS_START';
export const FETCH_MENTORS_ERROR = 'FETCH_MENTORS_ERROR';
export const FETCH_MENTORS_SUCCESS = 'FETCH_MENTORS_SUCCESS';
export const FETCH_MENTORS_CLEANUP = 'FETCH_MENTORS_CLEANUP';

export const FETCH_ADMINS_START = 'FETCH_ADMINS_START';
export const FETCH_ADMINS_ERROR = 'FETCH_ADMINS_ERROR';
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
export const FETCH_ADMINS_CLEANUP = 'FETCH_ADMINS_CLEANUP';

export const MENTEES_START = 'MENTEES_START';
export const MENTEES_SUCCESS = 'MENTEES_SUCCESS';
export const MENTEES_FAIL = 'MENTEES_FAIL';
export const MENTEES_CLEANUP = 'MENTEES_CLEANUP';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const CREATE_USER_CLEANUP = 'CREATE_USER_CLEANUP';

export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
export const GET_PROFILE_CLEANUP = 'GET_PROFILE_CLEANUP';

export const MENTORSHIP_START = 'MENTORSHIP_START';
export const MENTORSHIP_FAIL = 'MENTORSHIP_FAIL';
export const MENTORSHIP_SUCCESS = 'MENTORSHIP_SUCCES';
export const MENTORSHIP_CLEANUP = 'MENTORSHIP_CLEANUP';

export const DELETE_ADMIN_START = 'DELETE_ADMIN_START';
export const DELETE_ADMIN_SUCCESS = ' DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAIL = 'DELETE_ADMIN_FAIL';
export const DELETE_ADMIN_CLEANUP = 'DELETE_ADMIN_CLEANUP';

export const GET_CYCLES_START = 'GET_CYCLES_START';
export const GET_CYCLES_FAIL = 'GET_CYCLES_FAIL';
export const GET_CYCLES_SUCCESS = 'GET_CYCLES_SUCCES';
export const GET_CYCLES_CLEANUP = 'GET_CYCLES_CLEANUP';

export const GET_ONE_CYCLE_START = 'GET_ONE_CYCLE_START';
export const GET_ONE_CYCLE_FAIL = 'GET_ONE_CYCLE_FAIL';
export const GET_ONE_CYCLE_SUCCESS = 'GET_ONE_CYCLE_SUCCES';
export const GET_ONE_CYCLE_CLEANUP = 'GET_ONE_CYCLE_CLEANUP';

export const CREATE_CYCLE_START = 'CREATE_CYCLE_START';
export const CREATE_CYCLE_FAIL = 'CREATE_CYCLE_FAIL';
export const CREATE_CYCLE_SUCCESS = 'CREATE_CYCLE_SUCCES';
export const CREATE_CYCLE_CLEANUP = 'CREATE_CYCLE_CLEANUP';

export const EDIT_CYCLE_START = 'EDIT_CYCLE_START';
export const EDIT_CYCLE_FAIL = 'EDIT_CYCLE_FAIL';
export const EDIT_CYCLE_SUCCESS = 'EDIT_CYCLE_SUCCES';
export const EDIT_CYCLE_CLEANUP = 'EDIT_CYCLE_CLEANUP';

export const UPLOAD_MENTORS_START = 'UPLOAD_MENTORS_START';
export const UPLOAD_MENTORS_FAIL = 'UPLOAD_MENTORS_FAIL';
export const UPLOAD_MENTORS_SUCCESS = 'UPLOAD_MENTORS_SUCCES';
export const UPLOAD_MENTORS_CLEANUP = 'UPLOAD_MENTORS_CLEANUP';

export const GET_DASHBOARD_DATA_START = 'GET_DASHBOARD_DATA_START';
export const GET_DASHBOARD_DATA_FAIL = 'GET_DASHBOARD_DATA_FAIL';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCES';
export const GET_DASHBOARD_DATA_CLEANUP = 'GET_DASHBOARD_DATA_CLEANUP';
