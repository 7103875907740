import './index.css';
import {
    Card,
    CardBody, 
    CardImg, 
    CardTitle, 
    CardSubtitle, 
    Row, 
    Col, 
} from 'reactstrap';


//SVGS
import { ReactComponent as Delete } from '../../svg/delete.svg';
import { ReactComponent as Edit } from '../../svg/edit.svg';
import { ReactComponent as Facebook } from '../../svg/facebook.svg';
import { ReactComponent as Instagram } from '../../svg/instagram.svg';
import { ReactComponent as Twitter } from '../../svg/twitter.svg';
import { ReactComponent as LinkedIn } from '../../svg/linkedin.svg';

//IMAGES
import maleImg from "../../assets/images/male.png";
import femaleImg from "../../assets/images/female.jpeg";


const MentorItem = ({ mentor, toggleModal }) => {

    return (
    <> 
        <Card className="mentorItem p-3" onClick={() => toggleModal(mentor.uuid)}>
            <Row>
                <Col xs='12' md='3' xl='2'>
                    <CardImg
                        src={mentor.gender === 'female' ? femaleImg : maleImg}
                        alt='mentorsImg'
                        height={132}
                        width={148}
                    />
                </Col>
                <Col xs='12' md='7' xl='8' className='mentor-details'>
                    <CardBody>
                        <CardTitle>{mentor.firstName + " " + mentor.lastName} </CardTitle>
                        <CardSubtitle className='mmc-text-primary'>{mentor.areaOfExpertise}</CardSubtitle>
                        <div className='social-media-icons'>
                            <Facebook />
                            <Instagram />
                            <Twitter />
                            <LinkedIn />
                            <span className='username'>@{mentor.firstName}</span>
                        </div>
                    </CardBody>
                </Col>
                <Col xs='12' md='2' className='edit-delete'>
                    <div> <Edit /> </div>
                    <div> <Delete /> </div>
                </Col>
            </Row>
        </Card>
    </>
    )
}

export default MentorItem;

