import * as types from '../../action-types';
import { createCycle as initialState } from '../initialStates';

const createCycle = (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {
        case types.CREATE_CYCLE_START:
            return {
                ...state,
                isLoading: true,
            }
        case types.CREATE_CYCLE_SUCCESS:
            return {
                ...state,
                isSuccessful: true,
                data: payload,
            }
        case types.CREATE_CYCLE_FAIL:
            return {
                ...state,
                error: payload
            }
        case types.CREATE_CYCLE_CLEANUP:
            return {
                ...state,
                error: null,
                isSuccessful: false
            }
        default:
            return state;
    };
};

export default createCycle;

