const feats = [
  {
    id: 1,
    text: 'Finance',
    value: 'finance',
    overlay:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, fugiat?',
  },
  {
    id: 2,
    text: 'Journalism',
    value: 'journalism',
    overlay:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, fugiat?',
  },
  {
    id: 3,
    text: 'Medicine',
    value: 'medicine',
    overlay:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, fugiat?',
  },
  {
    id: 4,
    text: 'Management',
    value: 'management',
    overlay:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, fugiat?',
  },
  {
    id: 5,
    text: 'Technology',
    value: 'technology',
    overlay:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, fugiat?',
  },
  {
    id: 6,
    text: 'Education',
    value: 'education',
    overlay:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, fugiat?',
  },
  {
    id: 7,
    text: 'Engineering',
    value: 'engineering',
    overlay:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, fugiat?',
  },
  {
    id: 8,
    text: 'Others',
    value: 'others',
    overlay:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, fugiat?',
  },
];

export default feats;
