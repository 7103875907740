import React from 'react';
import { Row, Col, CardImg, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Button } from 'antd';

//svgs
import { ReactComponent as Delete } from '../../svg/delete.svg';

//images
import maleImg from '../../assets/images/male.png';
import femaleImg from '../../assets/images/female.jpeg';

const MenteeCard = ({ mentee, matchMentor }) => (
    <Row className='no-gutters mentee-card'>
        <Col md='2' className='p-2'>
            <CardImg
                src={mentee.avatar ? mentee.avatar : mentee.gender === 'female' ? femaleImg : maleImg}
                alt='mentee image'
                height={132}
                width={148}
            />
        </Col>
        <Col md='10' className='card-content'>
            <CardBody>
                <CardTitle>{mentee.firstName} {mentee.lastName}</CardTitle>
                <CardSubtitle className='mmc-text-primary'>{mentee.areaOfExpertise}</CardSubtitle>
            </CardBody>
            <div>
                <Button onClick={()=>matchMentor(mentee.uuid)} type='primary' shape='round' className='m-3' size='middle'>Match to Mentor</Button>
                <Delete />
            </div>
        </Col>
    </Row>
);

export default MenteeCard;
