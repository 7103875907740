import AxiosCall from '../../../utils/axios'
import ErrorHandler from '../../../utils/error-handler';
import * as types from '../../action-types';

export const mentorshipStart = () => ({
    type: types.MENTORSHIP_START,
  });
  
  export const mentorshipSuccess = payload => ({
    type: types.MENTORSHIP_SUCCESS,
    payload,
  });
  
  export const mentorshipFail = payload => ({
    type: types.MENTORSHIP_FAIL,
    payload,
  });
  
  export const mentorshipCleanup = () => ({
    type: types.MENTORSHIP_CLEANUP,
  });
  
  export const mentorship = payload => async dispatch => {
    try {
      dispatch(mentorshipStart());
      const requestObj = {
        path: 'mentorships',
        method: 'POST',
        data: payload
      };
      
      const { data } = await AxiosCall(requestObj);
      dispatch(mentorshipSuccess(data));
    } catch (err) {
      const error = ErrorHandler(err);
      dispatch(mentorshipFail(error));
    }
  };
  