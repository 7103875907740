import AxiosCall from '../../../utils/axios'
import ErrorHandler from '../../../utils/error-handler';
import * as types from '../../action-types';

export const menteesStart = () => ({
  type: types.MENTEES_START,
});

export const menteesSuccess = payload => ({
  type: types.MENTEES_SUCCESS,
  payload,
});

export const menteesFail = payload => ({
  type: types.MENTEES_FAIL,
  payload,
});

export const menteesCleanup = () => ({
  type: types.MENTEES_CLEANUP,
});

export const mentees = payload => async dispatch => {
  const { page = 1, limit = 10 } = payload;
  try {
    dispatch(menteesStart());
    const requestObj = {
      path: `users?role=mentee&page=${page}&limit=${limit}`,
      method: 'GET',
    };
    
    const { data, meta } = await AxiosCall(requestObj);
    dispatch(menteesSuccess({ data, meta }));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(menteesFail(error));
  }
};
