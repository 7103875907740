import './index.css'

import React from 'react';
import { Card, CardBody, CardHeader, CardSubtitle } from 'reactstrap';
import { Button, Progress } from 'antd';
import moment from 'moment'

import { ReactComponent as Edit } from '../../svg/edit.svg'
import { useDispatch } from 'react-redux';
import { editCycle } from '../../store/actions/edit-cycle';

const CycleCard = ({ cycle, onClick }) => {
    const dispatch = useDispatch()
    const calcPercent = () => {
        const startDate = new Date(cycle.startDate)
        const endDate = new Date(cycle.endDate)
        const currentDate = new Date()
        const range = endDate - startDate;
        const current = currentDate - startDate
        
        return (current/range) * 100
    }

    const activateDeactivate = () => {
        dispatch(editCycle({ uuid: cycle.uuid, data: { status: cycle.status === 'active' ? 'inactive' : 'active' }}))
    }

    return (
        <div className={`cycle-card col-md-3 `}>
            <Card className={`${cycle?.status ? 'active' : 'inactive'}`}>
                <CardHeader className='d-flex justify-cont-between'>
                    <h1 className='mmc-text-primary cycle-title'>{cycle?.title}</h1>
                    <Edit onClick={onClick} />
                </CardHeader>
                <CardBody>
                    <div className='section'>
                        <div>
                            <CardSubtitle>Start Date:</CardSubtitle>
                            <p>{moment(cycle?.startDate).format('D - M - YYYY')}</p>
                        </div>
                        <div>
                            <CardSubtitle>Mentees</CardSubtitle>
                            <p className='value'>{cycle.mentees ? cycle.mentees : 0}</p>
                        </div>
                    </div>
                    <div className='section'>
                        <div>
                            <CardSubtitle>End Date:</CardSubtitle>
                            <p>{moment(cycle?.endDate).format('D - M - YYYY')}</p>
                        </div>
                        <div>
                            <CardSubtitle>Mentors</CardSubtitle>
                            <p className='value'>{cycle.mentors ? cycle.mentors : 0}</p>
                        </div>
                    </div>
                    <div className='section'>
                        <div>
                            <CardSubtitle>Application Closing Date:</CardSubtitle>
                            <p>{moment(cycle?.applicationCloseDate).format('D - M - YYYY')}</p>
                        </div>
                    </div>
                    <div>
                        <Progress type='line' percent={calcPercent()} status='active' strokeColor='#1E9B32' trailColor='white' showInfo={false} />
                    </div>
                    <div>
                        <Button type={`${cycle?.status === 'active' ? 'primary': 'secondary'}'`} onClick={activateDeactivate} shape='round' className='mx-0 my-3' size='middle'>{cycle?.status === 'active' ? 'Deactivate' : 'Activate'}</Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
};

export default CycleCard;
