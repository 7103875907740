import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getDashboardDataStart = () => ({
  type: types.GET_DASHBOARD_DATA_START
});

export const getDashboardDataSuccess  = payload => ({
  type: types.GET_DASHBOARD_DATA_SUCCESS,
  payload,
});

export const getDashboardDataError = payload => ({
  type: types.GET_DASHBOARD_DATA_FAIL,
  payload,
});

export const getDashboardDataCleanup = () => ({
  type: types.GET_DASHBOARD_DATA_CLEANUP,
});

export const getDashboardData = ( page=1, limit=10 ) => async dispatch => {
  try {
    dispatch(getDashboardDataStart())
    const requestObj = {
      path: `users/dashboard`,
      method: 'GET',
    };
    const { data, meta } = await AxiosCall(requestObj);
    dispatch(getDashboardDataSuccess({ data: data, meta }));
  } 
  catch (err) {
    const error = ErrorHandler(err);
    dispatch(getDashboardDataError(error));
  }
};
