import AxiosCall from '../../../utils/axios'
import ErrorHandler from '../../../utils/error-handler';
import * as types from '../../action-types';

export const createCycleStart = () => ({
    type: types.CREATE_CYCLE_START,
  });
  
  export const createCycleSuccess = payload => ({
    type: types.CREATE_CYCLE_SUCCESS,
    payload
  });
  
  export const createCycleFail = payload => ({
    type: types.CREATE_CYCLE_FAIL,
    payload,
  });
  
  export const createCycleCleanup = () => ({
    type: types.CREATE_CYCLE_CLEANUP,
  });
  
  export const createCycle = payload => async dispatch => {
    try {
      dispatch(createCycleStart());
      const requestObj = {
        path: 'cycles',
        method: 'POST',
        data: payload
      };
      
      const { data } = await AxiosCall(requestObj);
      dispatch(createCycleSuccess(data));
    } catch (err) {
      const error = ErrorHandler(err);
      dispatch(createCycleFail(error));
    }
  };
  