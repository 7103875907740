import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Protected = ({children}) => {
    const token = localStorage.getItem('authToken');
    const location = useLocation();
    const profile = useSelector(s => s.getProfile);

    if (!token || (profile?.data && profile.data?.role !== 'admin' && profile.data?.role !== 'superadmin' )) {
        return <Navigate to="/" state={{ from: location }} />;
    }
    return children
}

export default Protected