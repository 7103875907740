import { combineReducers } from 'redux';
import login from './login';
import mentors from './mentors';
import mentees from './mentees';
import createUser from './create-user';
import getProfile from './get-profile';
import mentorship from './mentorship'
import admins from './admins';
import deleteAdmin from './deleteAdmin';
import getCycles from './get-cycles';
import getOneCycle from './get-one-cycle';
import editCycle from './edit-cycle';
import createCycle from './create-cycle';
import uploadMentors from './upload-mentors';
import dashboard from './get-dashboard-data';

const baseReducers = combineReducers({
    login,
    mentors,
    mentees,
    createUser,
    getProfile,
    mentorship,
    admins,
    deleteAdmin,
    createCycle,
    getCycles,
    getOneCycle,
    editCycle,
    uploadMentors,
    dashboard
});

export default baseReducers;
