import AxiosCall from '../../../utils/axios'
import ErrorHandler from '../../../utils/error-handler';
import * as types from '../../action-types';

export const getCyclesStart = () => ({
    type: types.GET_CYCLES_START,
  });
  
  export const getCyclesSuccess = payload => ({
    type: types.GET_CYCLES_SUCCESS,
    payload,
  });
  
  export const getCyclesFail = payload => ({
    type: types.GET_CYCLES_FAIL,
    payload,
  });
  
  export const getCyclesCleanup = () => ({
    type: types.GET_CYCLES_CLEANUP,
  });
  
  export const getCycles = (active=false) => async dispatch => {
    try {
      dispatch(getCyclesStart());
      const requestObj = {
        path: active ? `cycles/active?opened=${true}` : 'cycles',
        method: 'GET'
      };
      
      const { data } = await AxiosCall(requestObj);
      dispatch(getCyclesSuccess(data));
    } catch (err) {
      const error = ErrorHandler(err);
      dispatch(getCyclesFail(error));
    }
  };
  