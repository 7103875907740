import './index.css';

import { Button, DatePicker, Form, Input, InputNumber, message } from 'antd';
import React, { useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { createCycle, createCycleCleanup } from '../../store/actions/create-cycle';
import { editCycle, editCycleCleanup } from '../../store/actions/edit-cycle';
import { getCycles } from '../../store/actions/get-cycles';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';

const AddCycleCard = ({ newModal, toggle, uuid, closeModal, data }) => {
    const createCycleState = useSelector(s => s.createCycle);
    const editCycleState = useSelector(s => s.editCycle);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onFinish = (values) => {
        if (!uuid) {
            dispatch(createCycle(values));
        } else {
            dispatch(editCycle({uuid, data: values}));
        }
    };

    const close = () => {
        form.resetFields();
        closeModal();
    };

    const toggleModal = () => {
        form.resetFields();
        toggle();
    };

    useEffect(()=> {
        if (data) {
            form.setFieldsValue({
                ...data,
                startDate: moment(data?.startDate),
                endDate: moment(data?.endDate),
                applicationCloseDate: moment(data?.applicationCloseDate),
            });
        } else {
            form.resetFields();
        }
    }, [data]);

    useEffect(() => {
        if (createCycleState.isSuccessful) {
            message.success('Cycle created successfully');
            close();
            dispatch(getCycles());
            dispatch(createCycleCleanup());
        } else if (createCycleState.error) {
            message.error(createCycleState.error);
            dispatch(createCycleCleanup());
        }
    }, [createCycleState]);

    useEffect(() => {
        if (editCycleState.isSuccessful) {
            message.success('Cycle updated successfully');
            close();
            dispatch(getCycles());
            dispatch(editCycleCleanup());
        } else if (editCycleState.error) {
            message.error(editCycleState.error);
            dispatch(editCycleCleanup());
        }
    }, [editCycleState])



    return (
        <Modal isOpen={newModal} toggle={toggleModal} className='add-cycle-modal'>
            <ModalHeader>
                <h3>Edit Cycle</h3>
                <CloseOutlined onClick={close} />
            </ModalHeader>
            <ModalBody>
                <Form
                    form={form} 
                    colon={false}
                    requiredMark="optional" 
                    onFinish={onFinish}
                    layout='vertical'
                >
                    <Form.Item
                        label="TITLE"
                        name="title"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Please enter a title"
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <div className='date-range'>
                        <Form.Item
                            label='ADD MENTEE'
                            name='maxMentees'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Field is required"
                                }
                            ]}
                        >
                            <InputNumber 
                                min={0}
                                controls={{
                                    upIcon: <PlusOutlined size={20} color={"white"} />,
                                    downIcon: <MinusOutlined size={20} color={"white"} />
                                }}
                                autoFocus={true}
                            />
                        </Form.Item>
                        <Form.Item
                            label='MENTEE TO MENTOR'
                            name='maxMenteesToMentor'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Field is required"
                                }
                            ]}
                        >
                            <InputNumber min={1} controls={{
                                upIcon: <div><PlusOutlined /></div>,
                                downIcon: <MinusOutlined />
                            }} autoFocus={true} />
                        </Form.Item>
                    </div>
                    <div className='date-range'>
                        <Form.Item
                            label='START'
                            name='startDate'
                            hasFeedback
                            className='start'
                            rules={[{
                                required: true,
                                message: "please chosse the start date"
                            }]}
                        >
                            <DatePicker />
                        </Form.Item>
                        <Form.Item
                            label='END'
                            name='endDate'
                            dependencies={['startDate']}
                            hasFeedback
                            className='end'
                            rules={[
                                {
                                    required: true,
                                    message: "please chosse the end date"
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            getFieldValue('startDate') < value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Please choose a later date!')
                                    }
                                })
                            ]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </div>
                    <div className='date-range'>
                        <Form.Item
                            label='Application Closing Date'
                            name='applicationCloseDate'
                            dependencies={['startDate', 'endDate']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "please choose the application closing date"
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            getFieldValue('startDate') < value && getFieldValue('endDate') > value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Please choose a date between start and end date!')
                                    }
                                })
                            ]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </div>
                    <Form.Item
                        label='NOTE'
                        name='note'
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <div className='button-container'>
                        <Button type='primary' htmlType='submit' shape='round' className='mx-3'>Save</Button>
                        <Button type='ghost' onClick={() => toggle()} shape='round' >Cancel</Button>
                    </div>
                    
                 </Form>
            </ModalBody>
        </Modal>
    );
};

export default AddCycleCard;
