import * as types from '../../action-types';
import { admins as initialState } from '../initialStates';

const admins = (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {
        case types.FETCH_ADMINS_START:
            return {
                ...state,
                isLoading: true,
            }
        case types.FETCH_ADMINS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccessful: true,
                data: payload,
            }
        case types.FETCH_ADMINS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case types.FETCH_ADMINS_CLEANUP:
            return {
                ...state,
                isSuccessful: false,
                error: null,
            }
        default:
            return state;
    };
};

export default admins;

