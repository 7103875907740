import './index.css';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CreateAdminForm, AdminItem, PaginationComponent } from '../../components';

import {
    Container, 
    Row, 
    Col
} from 'reactstrap'
import { Button } from 'antd';


//SVGS
import { ReactComponent as AddUser } from '../../svg/adduser.svg';


import { fetchAdmins, fetchAdminsCleanup } from '../../store/actions/fetchAdmins';
import { deleteAdmin, deleteAdminCleanup } from '../../store/actions/deleteAdmin';

//hooks
import useQuery from '../../hooks/useQuery';



const Admins = () => {
    const [openModal, setOpenModal] = useState(false)
    const [createAdminModal, setCreateAdminModal] = useState(false)
    const [admins, setAdmins] = useState([])
    const dispatch = useDispatch();
    const fetchAdminsState = useSelector( state => state.admins);
    const [page, setPage] = useState(1);
    const query = useQuery();
    const location = useLocation();

    //delete admin task
    const {isSuccessful:isSuccessfulDelAdmin, error:deleteAdminError } = useSelector( state => state.deleteAdmin);

    const handleDelete = (uuid) => {
        if(deleteAdminError){
            dispatch(deleteAdminCleanup());
        }
        dispatch(deleteAdmin(uuid));
    }

    
    //toggle card modal  admin-list
    const toggleModal = () => {
        setOpenModal(!openModal);
    };

    //toggle create-admin form modal
    const createAdminToggleModal = () => {
        setCreateAdminModal(!createAdminModal);
    }

     //set Pagination
     const [pageSize, setPageSize] = useState(10);
     const [totalAdmins, setTotalAdmins] = useState(0);


    useEffect(()=> {
        if (!page || isSuccessfulDelAdmin) {
            dispatch(fetchAdmins(1));
        } else {
            dispatch(fetchAdmins(page));
        }
    }, [dispatch, isSuccessfulDelAdmin, page]);


    useEffect(()=> {
        const newPage = query.get('page');
        if (newPage) setPage(newPage);
    }, [location, query]);


    useEffect(() => {
        const { data, error, isSuccessful } = fetchAdminsState;
      
        if (isSuccessful) {
            const adminsOnly = data.data;
            const activeAdmins = adminsOnly.filter(activeAdmin => !activeAdmin.isDeleted)
            setAdmins(activeAdmins);
            setTotalAdmins(pageSize * data.meta.total)
            setPageSize(data.meta.limit)
            dispatch(fetchAdminsCleanup())
        } 
        else if (error) {
            dispatch(fetchAdminsCleanup())
        }
    }, [dispatch, fetchAdminsState, pageSize])



    return (
        <div className="adminItemPage">
            <Container>
                <Row className='adminHeader'>
                    <Col xs='6' id='admin'>
                        Admin
                    </Col>
                    <Col xs='6'>
                        <Button 
                            type='primary' 
                            icon={<AddUser className='me-3' />} 
                            id='addAdmin' 
                            shape='round'
                            onClick={createAdminToggleModal}
                        > 
                            Create Admin 
                        </Button>
                    </Col>
                </Row>
                <Row className='card-modal-list'>
                    {
                        admins.map(admin => {
                            return (
                                <Col xs='12' key={admin.uuid}>
                                    <AdminItem toggleModal={toggleModal} admin={admin}
                                        handleDelete={handleDelete}
                                        uuid={admin.uuid}
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
                <Row className='paginationComponent'>
                    <Col xs='12' >
                        <PaginationComponent 
                            current={page}
                            pageSize={pageSize}
                            total={totalAdmins}
                            url={location.pathname}
                        /> 
                    </Col>
                </Row>
                <Row>
                    <Col xs='12'>
                        <CreateAdminForm 
                            createAdminModal={createAdminModal}
                            createAdminToggleModal={createAdminToggleModal}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default Admins;

