import './index.css';
import React from 'react';
import { Modal } from 'reactstrap';
import { Upload, Tag } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { uploadMentors } from '../../store/actions/upload-mentors'

const UploadMentors = ({ newModal, toggleModal }) => {
    const { Dragger } = Upload;
    const dispatch = useDispatch();

    const headers = [
        'title', 'firstName', 'lastName', 'email', 'phone', 'gender', 
        'yearsOfExperience', 'areaOfExpertise', 'bio', 'profession'
    ]

    const upload = (e) => {
        dispatch(uploadMentors({ file: e.file }))
    };

    return (
        <Modal isOpen={newModal} toggle={toggleModal} className='upload-mentor-modal'>
            <h3 className='text-white'>Upload Mentors</h3>
            <p>Ensure the file (xls/csv) has the following headers on the first row: </p>
            <div>{
                headers.map(header => (
                    <Tag color='orange'>{header}</Tag>
                ))
            }</div>
            <br />
            <Dragger 
                accept='.xls, .csv, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                customRequest={upload}
            >
                <InboxOutlined />
                <p>Drag and drop file here or click to upload</p>
                
            </Dragger>
        </Modal>
    )
};

export default UploadMentors;
