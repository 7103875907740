import Axios from 'axios';

const SERVER_HOST = process.env.REACT_APP_SERVER_HOST;
let baseURL;
if (SERVER_HOST) {
  baseURL = `${SERVER_HOST}/api/`;
} else {
  const host = 'https://mmc-backend-staging.herokuapp.com';
  baseURL = `${host}/api/`;
}

const AxiosCall = async requestObj => {
  const { path, method, data, contentType, version = 'v1' } = requestObj;

  const token = localStorage.getItem('authToken');

  const headers = {
    Authorization: `Bearer ` + token,
    'Content-Type': contentType || 'application/json',
  };

  const url = version ? `${baseURL}${version}/${path}` : `${baseURL}${path}`;
  try {
    const response = await Axios({ method, url, data, headers, json: true });
    const result = response && response.data;
    return result;
  } catch (error) {
    if (error.response.data.status === 401) {
      localStorage.removeItem('authToken');
    }
    throw error;
  }
};

export default AxiosCall;
