import './index.css';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {Alert, Form, Input, Button, Typography, Row, Col} from 'antd';

//actions
import { login, loginCleanup } from '../../store/actions/login';
import { getProfile } from '../../store/actions/get-profile';

//components
import Loader from '../../components/Loader';

const mentorshipUrl = process.env.REACT_APP_MENTORSHIP_URL;

const Login = () => {
    const {Title} = Typography;

    const [error, setError] = useState();
    const dispatch = useDispatch();
    const loginState = useSelector( state => state.login);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onFinish = values => {
        if(error) setError(null);
        if(error){
            setError(null);
        }
        dispatch(login(values));
    }
    
    useEffect(() => {
        if (loginState.isSuccessful) {
            if(loginState?.data?.role === 'admin' || loginState?.data?.role === 'superadmin'){
                dispatch(loginCleanup());
                dispatch(getProfile());
                form.resetFields();
                navigate('/dashboard', { replace: true });
            }else{
                setError('You are not authorirized to access this page');
                form.resetFields();
                dispatch(loginCleanup());
            }
          dispatch(loginCleanup());
          navigate('/dashboard');
        }
        else if (loginState.error) {
            setError(loginState.error);
            form.resetFields();
            dispatch(loginCleanup());
        }
    }, [loginState]);

    


    return (
        <div className="adminLogin">
            <Row>
                <Col span={24}>
                    <Title level={3}> LOGIN </Title>
                    <p id="welcome-note">Welcome on board</p>
                    {error ? 
                        (
                            <Alert message={error} type="error" showIcon closable />
                        ) : null
                    }
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form 
                        form={form} 
                        colon={false} 
                        requiredMark="optional" 
                        onFinish={onFinish}
                    >
                        <Form.Item 
                            label="Email address"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your email"
                                },
                                {
                                    type:"email",
                                    message:"Please enter a valid email"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item 
                            label="Password" 
                            className="password"
                        >
                            <a
                                href={`${mentorshipUrl}/reset-password`}
                                id="forgot-password"
                            >
                                Forgot Password?
                            </a>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter your password"
                                    },
                                    {
                                        min: 8,
                                        message: 'Minimum of eight characters required!',
                                    }
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                htmlType="submit"
                                className="login"
                                disabled={loginState.isLoading}
                            >
                                Login
                                {loginState.isLoading ? <Loader color='black' className='ms-2' /> : ''}
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default Login;

