import React from 'react';
import AdminLayout from '../components/layout';
import Admins from '../views/Admins';
import Cycles from '../views/Cycles';
import Dashboard from '../views/Dashboard'
import Login from '../views/Login'
import Mentees from '../views/Mentees';
import Mentors from '../views/Mentors'
import NotFound from '../views/NotFound'


import Protected from './Protected';

const routes = [
  {
    index: true,
    element: <Login />
  },
  {
    path: "/dashboard",
    element: <Protected><AdminLayout /></Protected>,
    children: [
      {
          index: true,
          element: <Dashboard />
      },
      {
        path: '/dashboard/admin',
        element: <Admins />
      },
      {
        path: '/dashboard/mentors',
        element: <Mentors />
      },
      {
        path: '/dashboard/mentees',
        element: <Mentees />
      },
      {
        path: '/dashboard/cycles',
        element: <Cycles />
      }
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;