import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

//size: small
const Loader = ({ size='default', color='#EF951B', children, style, className }) => {
  const LoadingIcon = <LoadingOutlined 
    style={{ fontSize: size === 'large' ? 40 : size === 'small' ? 16 : 24 }} 
    spin
  />;

  return (
    <Spin indicator={LoadingIcon} className={className} style={{ color: color, ...style }}>
      {children}
    </Spin>
  );
}

Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'default', 'large']),
  color: PropTypes.string
}

export default Loader;
