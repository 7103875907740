import './index.css';
import { useEffect } from 'react';
import { Container, Row, Col, CardImg, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Button, Table, Select, Popconfirm } from 'antd';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis,  Legend, Line } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';

//actions
import { getDashboardData, getDashboardDataCleanup } from '../../store/actions/get-dashboard-data'

//SVGS
import { ReactComponent as AddUser } from '../../svg/adduser.svg';
import { ReactComponent as Delete } from '../../svg/delete.svg';
import { ReactComponent as Dots } from '../../svg/3dots.svg';

//IMAGES
import image from '../../assets/images/image.png';
import { useState } from 'react';
import moment from 'moment';
import { editCycle, editCycleCleanup } from '../../store/actions/edit-cycle';
import { deleteAdmin, deleteAdminCleanup } from '../../store/actions/deleteAdmin';
import { CreateAdminForm } from '../../components';
import maleImg from '../../assets/images/male.png';
import femaleImg from '../../assets/images/female.jpeg';
import ModalMentorList from '../../components/ModalMentorList';

const { Option } = Select;

const data = [
  {
    "name": "Text A",
    "uv": 400,
    "pv": 240,
    "amt": 2400
  },
  {
    "name": "Text B",
    "uv": 300,
    "pv": 139,
    "amt": 2210
  },
  {
    "name": "Text C",
    "uv": 200,
    "pv": 580,
    "amt": 2290
  },
  {
    "name": "Text D",
    "uv": 278,
    "pv": 390,
    "amt": 2000
  },
  {
    "name": "Text E",
    "uv": 189,
    "pv": 480,
    "amt": 2181
  },
  {
    "name": "Text F",
    "uv": 239,
    "pv": 380,
    "amt": 2500
  },
  {
    "name": "Text G",
    "uv": 349,
    "pv": 430,
    "amt": 2100
  }
];

const Topdiv = () => (
    <div className='activities-topdiv'>
        <h4>Activities</h4>
        <span className='sort'>Sort by: 
            <Select defaultValue='a' size='small'>
                <Option value='a'>last 30 days</Option>
            </Select> </span>
    </div>
);

const tableData = [
    {
        title: 'Mission 2020',
        description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet...',
        date: '12 July, 2021 | 3:00AM',
        doc: 'InvestmentBanking01.pdf',
        actions: <Dots />,
    },
    {
        title: 'Mission 2020',
        description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet...',
        date: '12 July, 2021 | 3:00AM',
        doc: 'InvestmentBanking01.pdf',
        actions: <Dots />,
    },
    {
        title: 'Mission 2020',
        description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet...',
        date: '12 July, 2021 | 3:00AM',
        doc: 'InvestmentBanking01.pdf',
        actions: <Dots />,
    },
];

const tableColumn = [
    {
        name: 'Title',
        dataIndex: 'title',
        key: 'title'
    },
    {
        name: 'Description',
        dataIndex: 'description',
        key: 'description'
    },
    {
        name: 'Date',
        dataIndex: 'date',
        key: 'date'
    },
    {
        name: 'Supporting Documents',
        dataIndex: 'doc',
        key: 'doc'
    },
    {
        name: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: () => <Dots />
    }
];

const Dashboard = () => {
    const profile = useSelector(s => s.getProfile);
    const editCycleState = useSelector(s => s.editCycle);
    const dashboardState = useSelector(s => s.dashboard);
    const {isSuccessful:isSuccessfulDelAdmin, error:deleteAdminError } = useSelector( state => state.deleteAdmin);
    const [dashboard, setDashboard] = useState(null);
    const [createAdminModal, setCreateAdminModal] = useState(false);
    const dispatch = useDispatch();
    const [mentee, setMentee] = useState('');
    const [openMentorList, setOpenMentorList] = useState(false);

    useEffect(() => {
        dispatch(getDashboardData())
    }, [])

    useEffect(() => {
        if (dashboardState.isSuccessful) {
          dispatch(getDashboardDataCleanup());
          setDashboard(dashboardState.data)
        }
        else if (dashboardState.error) {
            dispatch(getDashboardDataCleanup());
        }
    }, [dashboardState]);

    useEffect(() => {
        if (editCycleState.isSuccessful) {
          dispatch(editCycleCleanup());
          dispatch(getDashboardData())
        }
        else if (editCycleState.error) {
            dispatch(editCycleCleanup());
        }
    }, [editCycleState]);

    useEffect(()=> {
        if (isSuccessfulDelAdmin) {
            dispatch(getDashboardData());
        }
    }, [dispatch, isSuccessfulDelAdmin]);

    const updateCycle = (uuid, status) => {
        dispatch(editCycle({ uuid, data: { status } }))
    };

    //delete admin task

    const handleDelete = (uuid) => {
        if(deleteAdminError){
            dispatch(deleteAdminCleanup());
        }
        dispatch(deleteAdmin(uuid));
    }

    const createAdminToggleModal = () => {
        setCreateAdminModal(!createAdminModal);
    }

    const toggle = () => setOpenMentorList(!openMentorList);

    const matchMentee = mentee => {
        setMentee(mentee);
        setOpenMentorList(true)
    };

    return (
        <div className="dashboard">
            <Container>
                <Row>
                    <h1>{`Welcome ${profile?.data?.firstName ?? ''},`}</h1>
                    <p className='mb-4'>you have (2) new notifcations</p>
                    <Col lg='6' className='pe-lg-5'>
                        <Row className="cards">
                            <Col md='5' className='card'>
                                <h1>{dashboard?.data?.users?.mentors ?? 0}</h1>
                                <p>Mentors</p>
                            </Col>
                            <Col md='5' className='card right'>
                                <h1>{dashboard?.data?.users?.mentees ?? 0}</h1>
                                <p>Mentees</p>
                            </Col>
                        </Row>
                        <section className='admin'>
                            <div className='head'>
                                <h2>Admin</h2>
                                <Button type='primary' shape='round' size='middle' onClick={createAdminToggleModal} icon={<AddUser className='me-3' />}> Create Admin</Button>
                            </div>
                            {
                                dashboard?.data?.admins?.map((admin, key) => (
                                    <Row className='card' key={key}>
                                        <Col md='8'>
                                            <h3>{admin.firstName} {admin.lastName}</h3>
                                            <p>Admin {key + 1}</p>
                                        </Col>
                                        <Col md='4'>
                                            {/* <Edit /> */}
                                            <Popconfirm
                                                title="Are you sure you want to delete this user admin?"
                                                onConfirm={() => handleDelete(admin.uuid)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Delete className='ms-4' />
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                )) 
                            }
                        </section>
                        <section className='mentorship'>
                            <h3>Mentorship Scheme</h3>
                            {
                                dashboard?.data?.cycles?.map(cycle => (
                                    <Row className='scheme-card' key={cycle?.uuid}>
                                        <Col md='3' className='scheme'><h3>{cycle.title}</h3></Col>
                                        <Col md='5' className='px-md-3 py-3 py-md-0'>
                                            <p className='cycle-date'>{moment(cycle.startDate).format('L')} - {moment(cycle.endDate).format('L')}</p>
                                            <p>Mentors: {cycle.mentors}</p>
                                            <p>Mentees: {cycle.mentees}</p>
                                        </Col>
                                        <Col md='4' className='actions'>
                                            <Button type='primary' shape='round' size='middle' onClick={() => updateCycle(cycle.uuid, 'active')}>Activate</Button>
                                            <Button type='secondary' shape='round' size='middle' className='ms-4 ms-md-0' onClick={() => updateCycle(cycle.uuid, 'inactive')}>Deactivate</Button>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </section>
                    </Col>
                    <Col lg='6' className='ps-lg-5'>
                        <div className='activities'>
                            <ResponsiveContainer width='100%' height={200}>
                                <LineChart data={data} margin={{top: 25, right: 25, bottom: 10, left: 20}}>
                                    <Legend content={Topdiv} verticalAlign='top' />
                                    <CartesianGrid strokeDasharray='3 3' />
                                    <XAxis dataKey='name' />
                                    <YAxis tickCount={50} />
                                    <Line type='monotone' dataKey='uv' stroke='#8884d8' />
                                    <Line type='monotone' dataKey='pv' stroke='#82ca9d' />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div className='activity'>
                            <div>
                                <h3>Activity Summary</h3>
                                <p>Search</p>
                            </div>
                            <Table dataSource={tableData} rowSelection={{}} >
                                {
                                    tableColumn.map(column => (
                                        <Table.Column key={column.key} dataIndex={column.dataIndex} title={column.name} />
                                    ))
                                }
                            </Table>
                        </div>
                        <div className='mentees'>
                            <h3>Mentees</h3>
                            {
                                dashboard?.data?.mentees.map(mentee => (
                                    <Row className='no-gutters mentee-card' key={mentee?.uuid}>
                                        <Col md='3' className='p-2'>
                                            <CardImg src={mentee.avatar ? mentee.avatar : mentee.gender === 'female' ? femaleImg : maleImg} alt='mentee image' />
                                        </Col>
                                        <Col md='9' className='card-content'>
                                            <CardBody>
                                                <CardTitle>{mentee?.firstName} {mentee.lastName}</CardTitle>
                                                <CardSubtitle className='mmc-text-primary'>{mentee?.areaOfExpertise}</CardSubtitle>
                                            </CardBody>
                                            <div>
                                                <Button onClick={()=>matchMentee(mentee.uuid)} type='primary' shape='round' className='m-3' size='middle'>Match to Mentor</Button>
                                                <Popconfirm
                                                    title="Are you sure you want to delete this user?"
                                                    onConfirm={() => handleDelete(mentee.uuid)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Delete className='ms-4' />
                                                </Popconfirm>
                                            </div>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs='12'>
                        <CreateAdminForm 
                            createAdminModal={createAdminModal}
                            createAdminToggleModal={createAdminToggleModal}
                        />
                    </Col>
                </Row>
            </Container>
            <ModalMentorList isOpen={openMentorList} mentee={mentee} toggle={toggle} />
        </div>
    );
};

export default Dashboard;
