import * as types from '../../action-types';
import { login as initialState } from '../initialStates';

const login = (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {
        case types.LOGIN_START:
            return {
                ...state,
                isLoading: true,
            }
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccessful: true,
                data: payload
            }
        case types.LOGIN_FAIL:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case types.LOGIN_CLEANUP:
            return {
                ...state,
                error: null,
                isSuccessful: false,
            }
        default:
            return state;
    };
};

export default login;
