import * as types from '../../action-types';
import { dashboard as initialState } from '../initialStates';

const dashboard = (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {
        case types.GET_DASHBOARD_DATA_START:
            return {
                ...state,
                isLoading: true,
            }
        case types.GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                isSuccessful: true,
                data: payload,
            }
        case types.GET_DASHBOARD_DATA_FAIL:
            return {
                ...state,
                error: payload
            }
        case types.GET_DASHBOARD_DATA_CLEANUP:
            return {
                ...state,
                error: null,
                isSuccessful: false
            }
        default:
            return state;
    };
};

export default dashboard;

