import './index.css'

import React, { useEffect } from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//svgs
import { ReactComponent as Category } from '../../svg/Category.svg';
import { ReactComponent as Profile } from '../../svg/Profile.svg';
import { ReactComponent as Settings } from '../../svg/Setting.svg';
import { ReactComponent as Vector } from '../../svg/Vector.svg';
import { ReactComponent as Admin } from '../../svg/Admin.svg';
import { ReactComponent as Logout } from '../../svg/Logout.svg';
import { ReactComponent as Friends } from '../../svg/Friends.svg';

//actions
import { getProfile } from '../../store/actions/get-profile';

//utils
import { handleLogout } from '../../utils/helpers';

const environment = process.env.REACT_APP_ENV;

const SiderMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const handleSelect = ({item}) => {
        if(item.props.to === '/logout'){
            handleLogout(dispatch);
            navigate('/');
        }else{
            navigate(item.props.to);
        }
    }

    useEffect(() => {
        dispatch(getProfile());
    }, []);

    return (
        <div className='sider-container'>
            <Menu className='mmc-sider' selectedKeys={[location.pathname]} onSelect={handleSelect}>
                <Menu.Item key='/dashboard' to='/dashboard' icon={<Category />}>Dashboard</Menu.Item>
                <Menu.Item key='/dashboard/mentees' to='/dashboard/mentees' icon={<Profile />}>Mentees</Menu.Item>
                <Menu.Item key='/dashboard/mentors' to='/dashboard/mentors' icon={<Profile />}>Mentors</Menu.Item>
                {environment !== 'production' ? (
                    <Menu.Item key='/dashboard/email' to='/dashboard/email' icon={<Vector />}>Bulk Email</Menu.Item>
                ) : null}
                <Menu.Item key='/dashboard/admin' to='/dashboard/admin' icon={<Admin />}>Admin</Menu.Item>
                <Menu.Item key='/dashboard/cycles' to='/dashboard/cycles' className='special' icon={<Friends />}>Mentorship Cycles Management</Menu.Item>
                {environment !== 'production' ? (
                    <Menu.Item key='/dashboard/settings' to='/dashboard/settings' icon={<Settings />}>Settings</Menu.Item>
                ) : null}
                <Menu.Item key='/logout' to='/logout' icon={<Logout />}>Log Out</Menu.Item>
            </Menu>
        </div>
    );
}

export default SiderMenu;
