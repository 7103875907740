import './index.css';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CreateUserForm, MentorItem, ModalMentorsProfile, PaginationComponent} from '../../components';
import {
    Container, 
    Row, 
    Col
} from 'reactstrap'
import { Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';


//SVGS
import { ReactComponent as AddUser } from '../../svg/adduser.svg';
import { fetchMentors, fetchMentorsCleanup } from '../../store/actions/mentors';

//hooks
import useQuery from '../../hooks/useQuery';
import UploadMentors from '../../components/UploadMentors';
import { uploadMentorsCleanup } from '../../store/actions/upload-mentors';



const Mentors = () => {
    const [openModal, setOpenModal] = useState(false)
    const [createUserModal, setcreateUserModal] = useState(false)
    const [uploadMentorsModal, setUploadMentorsModal] = useState(false)
    const [mentors, setMentors] = useState([])
    const [singleMentor, setSingleMentor] = useState([1])
    const dispatch = useDispatch();
    const fetchMentorsState = useSelector( state => state.mentors);
    const uploadMentorsState = useSelector(s => s.uploadMentors)
    const [page, setPage] = useState(1);
    const query = useQuery();
    const location = useLocation();
    
    //toggle card modal  mentor-list
    const toggleModal = (uuid) => {
        const mentor = mentors.filter(mentor => mentor.uuid === uuid);
        setOpenModal(!openModal);
        setSingleMentor(mentor);
    };

    //toggle create-mentor form modal
    const createUserToggleModal = () => {
        setcreateUserModal(!createUserModal);
    }

    const uploadMentorsToggleModal = () => setUploadMentorsModal(!uploadMentorsModal)

     //set Pagination
     const [pageSize, setPageSize] = useState(10);
     const [totalMentors, setTotalMentors] = useState(0);


    useEffect(()=> {
        if (!page) {
            dispatch(fetchMentors(1));
        } else {
            dispatch(fetchMentors(page));
        }
        
    }, [dispatch, page]);

    useEffect(()=> {
        const newPage = query.get('page');
        if (newPage) setPage(newPage);
    }, [location, query]);

    useEffect(() => {
        const { data, error, isSuccessful } = fetchMentorsState;
        if (isSuccessful) {
            const mentorsOnly = data.data;
            setMentors(mentorsOnly);
            setTotalMentors(pageSize * data.meta.total)
            setPageSize(data.meta.limit)
        } 
        else if (error) {
            dispatch(fetchMentorsCleanup())
        }
    }, [dispatch, fetchMentorsState, pageSize])

    useEffect(() => {
        const { error, isSuccessful } = uploadMentorsState;
        if (isSuccessful) {
            setUploadMentorsModal(false)
            message.success('mentors have been uploaded successfully');
            dispatch(uploadMentorsCleanup())
        } else if (error) {
            setUploadMentorsModal(false)
            message.error(error);
            dispatch(uploadMentorsCleanup())
        }
    }, [uploadMentorsState])


    return (
        <div className="mentorsPage">
            <Container>
                <Row className='mentorsHeader'>
                    <Col md='6' id='mentors'>
                        Mentors
                    </Col>
                    <Col md='6' className='add-buttons mt-4 mt-md-0'>
                        <Button 
                            type='primary' 
                            icon={<UploadOutlined style={{ fontSize: '20px', color: '#000'}} />} 
                            id='addMentor' 
                            shape='round'
                            onClick={uploadMentorsToggleModal}
                        > 
                            Upload Mentors 
                        </Button>
                        <Button 
                            type='primary' 
                            icon={<AddUser />} 
                            id='addMentor' 
                            shape='round'
                            onClick={createUserToggleModal}
                            className='ms-4'
                        > 
                            Add Mentor 
                        </Button>
                    </Col>
                </Row>
                <Row className='card-modal-list'>
                    {
                        mentors.map(mentor => (
                            <Col xs='12' key={mentor.uuid}>
                                <MentorItem toggleModal={toggleModal} mentor={mentor} />
                            </Col>
                        ))
                    }
                    {
                        singleMentor.map(profile => (
                            <Col xs='12' key={profile.uuid}>
                                <ModalMentorsProfile 
                                    profile={profile} 
                                    openModal={openModal} 
                                    toggleModal={toggleModal}  
                                />
                            </Col>
                        ))
                    }
                </Row>
                <Row className='paginationComponent'>
                    <Col xs='12' >
                        <PaginationComponent 
                            current={page}
                            pageSize={pageSize}
                            total={totalMentors}
                            url={location.pathname}
                        /> 
                    </Col>
                </Row>
                <Row>
                    <Col xs='12'>
                        <CreateUserForm 
                            createUserModal={createUserModal} 
                            createUserToggleModal={createUserToggleModal}
                            refreshPage={() => dispatch(fetchMentors(page))}
                        />
                    </Col>
                </Row>
                <UploadMentors newModal={uploadMentorsModal} toggleModal={uploadMentorsToggleModal} />
            </Container>
        </div>
    )
};

export default Mentors;

