import './index.css';
import React from 'react';
import { Pagination as AntPagination } from 'antd';
import { useNavigate } from 'react-router-dom';

const Pagination = ({ url, current = 1, onPaginate, total, pageSize }) => {
    const navigate = useNavigate();
    // const [page, setPage] = useState(current)

    const onChange = (page) => {
        if (url) {
            navigate(url + '?page=' + page);
        } else {
            onPaginate(page)
        } 
    };

    const InnerElement = ({ component, className = '' }) => (
        <div className={`innerElement ${className}`}>
            {component}
        </div>
    );

    const itemRender = (number, page, Elements) => {
        if((current == undefined && number === 1 && page === 'page') || (number == current && page === 'page')) {
            return <InnerElement component={Elements} className='current-page' />
        }
        return <InnerElement component={Elements} />
    };

    return (
        <div className="pagination">
            <AntPagination 
                onChange={onChange} 
                current={current} 
                total={total} 
                pageSize={pageSize} 
                itemRender={itemRender}
                showSizeChanger={false}
            />
        </div>
    );
};

export default Pagination;
