import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';
import { message as antMessage } from 'antd';

export const deleteAdminStart = () => ({
  type: types.DELETE_ADMIN_START,
});

export const deleteAdminSuccess = payload => ({
  type: types.DELETE_ADMIN_SUCCESS,
  payload,
});

export const deleteAdminFail = payload => ({
  type: types.DELETE_ADMIN_FAIL,
  payload,
});

export const deleteAdminCleanup = () => ({
  type: types.DELETE_ADMIN_CLEANUP,
});

export const deleteAdmin = (uuid) => async dispatch => {
  try {
    dispatch(deleteAdminStart());
    const requestObj = {
      path: `users/${uuid}`,
      method: 'DELETE',
    };
    const { message } = await AxiosCall(requestObj);
    antMessage.success('Admin deleted successfully');
    dispatch(deleteAdminSuccess(message));
  } 
  catch (err) {
    const error = ErrorHandler(err);
    dispatch(deleteAdminFail(error));
  }
};

