import './index.css';
import {
    Card,
    CardBody, 
    CardImg, 
    CardTitle, 
    CardSubtitle, 
    Container, 
    Row, 
    Col, 
    Modal, 
    ModalBody, 
    ModalHeader,
} from 'reactstrap';


//SVGS
import { ReactComponent as Facebook } from '../../svg/facebook.svg';
import { ReactComponent as Instagram } from '../../svg/instagram.svg';
import { ReactComponent as Twitter } from '../../svg/twitter.svg';
import { ReactComponent as LinkedIn } from '../../svg/linkedin.svg';

//IMAGES
import maleImg from "../../assets/images/male.png";
import femaleImg from "../../assets/images/female.jpeg";



const ModalMentorsProfile = ({profile, openModal, toggleModal }) => {
    return (
        <>
            <Modal isOpen={openModal} toggle={toggleModal} className='mentorsProfile'>
                <ModalHeader toggle={toggleModal}>
                    Mentor Profile
                </ModalHeader>
                <ModalBody>
                    <Container className='mentor-profile-div'>
                        <Row>
                            <Col xs='12' md='4'>
                                <Card className='px-3 pt-3'>
                                    <CardImg
                                        src={profile.gender === 'female' ? femaleImg : maleImg}
                                        alt='mentorsImg'
                                        height={132}
                                        width={148}
                                    />
                                    <CardBody>
                                        <CardTitle>{profile.firstName + " " + profile.lastName}</CardTitle>
                                        <CardSubtitle className='mmc-text-primary'>{profile.areaOfExpertise}</CardSubtitle>
                                    </CardBody>
                                </Card>
                                <div className='social-media-icons'>
                                    <Facebook />
                                    <Instagram />
                                    <Twitter />
                                    <LinkedIn />
                                    <span className='username'>@{profile.firstName}</span>
                                </div>
                            </Col>
        
                            <Col xs='12' md='8'>
                                <Row className='mentor-full-details'>
                                    <Col xs='12' className='details-column'>
                                        <p className='label'>Full Name:</p>
                                        <p className='value'>{profile.firstName + " " + profile.lastName}</p>
                                    </Col>
                                    <Col xs='12' className='details-column'>
                                        <p className='label'>Area of Expertise:</p>
                                        <p className='value'>{profile.areaOfExpertise}</p>
                                    </Col>
                                    <Col xs='12' className='details-column'>
                                        <p className='label'>Education:</p>
                                        <p className='value'>BSc.</p>
                                    </Col>
                                    <Col xs='12' className='details-column'>
                                        <p className='label'>Experience:</p>
                                        <p className='value'>4 years</p>
                                    </Col>
                                    <Col xs='12' className='details-column'>
                                        <p className='label'>Skills &amp; Interests:</p>
                                        <p className='value'>Surfing, Chatting, Coding, Teaching</p>
                                    </Col>
                                    <Col xs='12' className='details-column'>
                                        <p className='label'>Email:</p>
                                        <p className='value'>{profile.email}</p>
                                    </Col>
                                    <Col xs='12'>
                                        <Row>
                                            <Col xs='12' md='6' className='details-column'>
                                                <p className='label'>Phone Number:</p>
                                                <p className='value'>{profile.phone}</p>
                                            </Col>
                                            <Col xs='12' md='5' className='gender details-column'>
                                                <p className='label'>Gender:</p>
                                                <p className='value'>{profile.gender}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs='12' className='details-column career-brief'>
                                        <p className='label'>Career Brief:</p>
                                        <p className='value'> {profile.bio} </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>
               
        </>
    )
}

export default ModalMentorsProfile;

