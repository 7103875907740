import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const createUserStart = () => ({
  type: types.CREATE_USER_START,
});

export const createUserSuccess = payload => ({
  type: types.CREATE_USER_SUCCESS,
  payload,
});

export const createUserFail = payload => ({
  type: types.CREATE_USER_FAIL,
  payload,
});

export const createUserCleanup = () => ({
  type: types.CREATE_USER_CLEANUP,
});

export const createUser = payload => async dispatch => {
  try {
    dispatch(createUserStart());
    const requestObj = {
      path: 'users',
      method: 'POST',
      data: payload,
    };
    const { data } = await AxiosCall(requestObj);
    dispatch(createUserSuccess(data));
  } 
  catch (err) {
    const error = ErrorHandler(err);
    dispatch(createUserFail(error));
  }
};

