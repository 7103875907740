export const login = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null,
};

export const mentors = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null
}

export const mentees = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null,
}

export const createUser = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null
}

export const getProfile = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null,
}

export const mentorship = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null,
}
export const admins = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null
}

export const deleteAdmin = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null
}


export const getOneCycle = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null,
}

export const getCycles = {
    isLoading: false,
    isSuccessful: false,
    data: [],
    error: null,
}

export const createCycle = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null,
}

export const editCycle = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null,
}

export const uploadMentors = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null
}

export const dashboard = {
    isLoading: false,
    isSuccessful: false,
    data: null,
    error: null
}
