import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const fetchMentorsStart = () => ({
  type: types.FETCH_MENTORS_START
});

export const fetchMentorsSuccess  = payload => ({
  type: types.FETCH_MENTORS_SUCCESS,
  payload,
});

export const fetchMentorsError = payload => ({
  type: types.FETCH_MENTORS_ERROR,
  payload,
});

export const fetchMentorsCleanup = () => ({
  type: types.FETCH_MENTORS_CLEANUP,
});

export const fetchMentors = ( page=1, limit=10 ) => async dispatch => {
  try {
    dispatch(fetchMentorsStart())
    const requestObj = {
      path: `users?role=mentor&page=${page}&limit=${limit}`,
      method: 'GET',
    };
    const { data, meta } = await AxiosCall(requestObj);
    dispatch(fetchMentorsSuccess({ data: data, meta }));
  } 
  catch (err) {
    const error = ErrorHandler(err);
    dispatch(fetchMentorsError(error));
  }
};
