import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const fetchAdminsStart = () => ({
  type: types.FETCH_ADMINS_START
});

export const fetchAdminsSuccess  = payload => ({
  type: types.FETCH_ADMINS_SUCCESS,
  payload,
});

export const fetchAdminsError = payload => ({
  type: types.FETCH_ADMINS_ERROR,
  payload,
});

export const fetchAdminsCleanup = () => ({
  type: types.FETCH_ADMINS_CLEANUP,
});

export const fetchAdmins = ( page=1, limit=10 ) => async dispatch => {
  try {
    dispatch(fetchAdminsStart())
    const requestObj = {
      path: `users?role=admin&page=${page}&limit=${limit}`,
      method: 'GET',
    };
    const { data, meta } = await AxiosCall(requestObj);
    dispatch(fetchAdminsSuccess({ data, meta }));
  } 
  catch (err) {
    const error = ErrorHandler(err);
    dispatch(fetchAdminsError(error));
  }
};
