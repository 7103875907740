//styles
import './index.css';

//packages
import { Button, message, Select } from 'antd';
import { 
    Card, CardBody, CardFooter, CardImg, CardSubtitle, 
    CardTitle, Col, Container, Modal, ModalBody, ModalHeader, Row
} from 'reactstrap';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

//components
import Pagination from '../Pagination';
import ModalMentorsProfile from '../ModalMentorsProfile';

//images
import maleImg from '../../assets/images/male.png';
import femaleImg from '../../assets/images/female.jpeg';

//actions
import { fetchMentors, fetchMentorsCleanup } from '../../store/actions/mentors';
import { mentorship, mentorshipCleanup } from '../../store/actions/mentorship';
import { getCycles, getCyclesCleanup } from '../../store/actions/get-cycles';

const ModalMentorList = ({ isOpen, mentee, toggle }) => {
    const { Option } = Select;
    const [data, setData] = useState([]);
    const [mentor, setMentor] = useState({});
    const [meta, setMeta] = useState({});
    const [page, setPage] = useState(1);
    const mentorsState = useSelector(s => s.mentors);
    const mentorshipState = useSelector(s => s.mentorship);
    const dispatch = useDispatch();
    const [openProfile, setOpenProfile] = useState(false);
    const [loading, setLoading] = useState(false)
    const cyclesState = useSelector(s => s.getCycles);
    const [cycles, setCycles] = useState([]);
    const [selectedCycle, setSelectedCycle] = useState(null);

    const viewProfile = (mentor) => {
        setMentor(mentor)
        setOpenProfile(true);
    }

    const matchMentor = mentorId => {
        if(!selectedCycle) message.error('Please select a cycle', 1000);
        setLoading(true);
        dispatch(mentorship({
            mentee,
            mentor: mentorId,
            cycle: selectedCycle
        }));
    }

    const filter = {
        available: () => {

        },
        unavailable: () => {

        },
        male: () => {

        },
        female: () => {

        },
        technology: () => {
            
        },
        business: () => {

        }
    }

    useEffect(() => {
        dispatch(getCycles(true));
    }, [])


    useEffect(() => {
        if (mentorshipState.isSuccessful) {
            toggle();
            setLoading(false)
            message.success('Mentor assigned to mentee successfully');
            dispatch(mentorshipCleanup());
        } else if (mentorshipState.error) {
            setLoading(false)
            message.error(mentorshipState.error);
            dispatch(mentorshipCleanup());
        }
    }, [mentorshipState])

    useEffect(() => {
        if (mentorsState.isSuccessful) {
            setData(mentorsState.data.data);
            setMeta(mentorsState.data.meta);
            dispatch(fetchMentorsCleanup());
        } else if (mentorsState.error) {
            message.error(mentorsState.error);
            dispatch(fetchMentorsCleanup());
        }
    }, [mentorsState])

    useEffect(() => {
        if (cyclesState.isSuccessful) {
            setCycles(cyclesState.data);
            dispatch(getCyclesCleanup());
        } else if (cyclesState.error) {
            dispatch(getCyclesCleanup());
        }
    }, [cyclesState]);


    const profileToggle = () => {
        setOpenProfile(false);
    };

    const paginate = (page) => {
        setPage(page);
        dispatch(fetchMentors(page, 12));
    }

    const handleCycleSelect = value => {
        setSelectedCycle(value);
        dispatch(fetchMentors(1, 12));
    }


    return (
        <>
        <Modal
            isOpen={isOpen}
            toggle={toggle} 
            className='modal-mentor-list'
            onClosed={() =>{
                setSelectedCycle(null);
            }}
        >
            <ModalHeader toggle={toggle}>
                <Select
                    placeholder='Select cycle'
                    allowClear
                    className='cycle-select'
                    onChange={handleCycleSelect}
                >
                    {
                        cycles.map(cycle => (
                            <Option
                                key={cycle.uuid}
                                value={cycle.uuid}
                            >
                                {`${cycle.title} (${moment(cycle.startDate).format('L')}-${moment(cycle.endDate).format('L')})`}
                            </Option>
                        ))
                    }
                </Select>
            </ModalHeader>
            {selectedCycle && data.length ? (
                <ModalBody>
                    <Container className='mentor-cards'>
                        <Row className='mb-5'>
                            <div>
                                <p>Search with filter</p>
                                <div className='filter-button'>
                                    <button onClick={filter.male}>Male</button>
                                    <button onClick={filter.female}>Female</button>
                                    <button onClick={filter.available}>Available</button>
                                    <button onClick={filter.unavailable}>Unavailable</button>
                                    <button onClick={filter.technology}>Technology</button>
                                    <button onClick={filter.business}>Business</button>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            {
                                data.map(mentor => (
                                    <Col md='3' sm='4'>
                                    <Card>
                                        <CardImg 
                                            top
                                            src={`${mentor.avatar ? mentor.avatar : mentor.gender === 'female' ? femaleImg : maleImg}`}
                                            alt='Mentor image'
                                            height={135}
                                            width={153}
                                        />
                                        <CardBody>
                                            <CardTitle>{mentor.firstName} {mentor.lastName}</CardTitle>
                                            <CardSubtitle>{mentor.areaOfExpertise}</CardSubtitle>
                                        </CardBody>
                                        <CardFooter>
                                            <Button disabled={loading} onClick={()=>matchMentor(mentor.uuid)} type='primary' shape='round' size='small'>Select mentor</Button>
                                            <Button disabled={loading} onClick={()=>viewProfile(mentor)} type='secondary' shape='round' size='small'>View details</Button>
                                        </CardFooter>
                                    </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Container>
                    {meta.total && <Pagination current={page} total={meta.total * meta.limit} onPaginate={paginate} pageSize={meta.limit} />}
                </ModalBody>
            ) : null}
        </Modal>
        <ModalMentorsProfile profile={mentor} openModal={openProfile} toggleModal={profileToggle} />
        </>
    )
};

export default ModalMentorList;
