import * as types from '../../action-types';
import { mentors as initialState } from '../initialStates';

const mentors = (state = initialState, action) => {
    const { payload, type } = action;

    switch (type) {
        case types.FETCH_MENTORS_START:
            return {
                ...state,
                isLoading: true,
            }
        case types.FETCH_MENTORS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccessful: true,
                data: payload,
            }
        case types.FETCH_MENTORS_ERROR:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case types.FETCH_MENTORS_CLEANUP:
            return {
                ...state,
                error: null,
                isSuccessful: false
            }
        default:
            return state;
    };
};

export default mentors;

