import './index.css';
import {
    Button,
    Card,
    CardBody, 
    CardTitle, 
    CardSubtitle, 
    Row, 
    Col, 
} from 'reactstrap';
import { Popconfirm } from 'antd';


//SVGS
import { ReactComponent as Delete } from '../../svg/delete.svg';




const AdminItem = ({ admin, uuid, handleDelete }) => {

    return (
    <> 
        <Card className="adminItem p-3">
            <Row>
                <Col xs='12' md='10' className='admin-details'>
                    <CardBody>
                        <CardTitle>{admin.firstName + " " + admin.lastName} </CardTitle>
                        <CardSubtitle className='mmc-text-primary'>{admin.email}</CardSubtitle>
                    </CardBody>
                </Col>
                <Col xs='12' md='2' className='edit-delete'>
                    <Popconfirm
                        title="Are you sure you want to delete this user admin?"
                        onConfirm={() => handleDelete(uuid)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>
                            <Delete /> 
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
        </Card>
    </>
    )
}

export default AdminItem;

