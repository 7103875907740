import './index.css';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, 
    ModalBody, 
    ModalHeader,
} from 'reactstrap';
import {
    Alert, 
    Form, 
    Input, 
    InputNumber, 
    Button, 
    Row, 
    Col, 
    Select, 
    Upload,
    message
} from 'antd';

//actions
import { createUser, createUserCleanup } from '../../store/actions/create-user';

//svgs
import { ReactComponent as Camera } from '../../svg/Camera.svg';

//utils
import feats from '../../utils/feats';



const CreateUserForm = ({createUserModal, createUserToggleModal, refreshPage }) => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const dispatch = useDispatch();
    const [createUserError, setcreateUserError] = useState();
    const createUserState = useSelector( state => state.createUser);
    

    const onFinish = values => {
        if(createUserError){
            setcreateUserError(null);
        }

        const mentorData = {
            ...values,
            email: values.email.toLowerCase(),
            role: 'mentor'
        }
        dispatch(createUser(mentorData));
    }

    const renderFeats = () => feats?.map((feat, i) => (
        <Option key={i} value={feat.value.toLowerCase()}>{feat.text}</Option>
    )) ?? [];

    useEffect(() => {
        if (createUserState.isSuccessful) {
          form.resetFields();
          message.success("User successfully created!");
          dispatch(createUserCleanup());
          if(refreshPage) refreshPage();
          createUserToggleModal();
        } else if (createUserState.error) {
            setcreateUserError(createUserState.error);
            dispatch(createUserCleanup());
        }
    }, [createUserState]);
    


    return (
        <>
            <Modal isOpen={createUserModal} toggle={createUserToggleModal} className='createUser'>
                <Form 
                    form={form}
                    layout='vertical'
                    colon={false} 
                    onFinish={onFinish} 
                >
                    <ModalHeader toggle={createUserToggleModal}>
                        <Form.Item
                            name="upload"
                            valuePropName="fileList"
                        >
                            <Upload name="logo" action="/upload.do" listType="picture">
                                <Button icon={<Camera title='camera'/>}></Button>
                            </Upload>
                        </Form.Item>
                    </ModalHeader>
                    <ModalBody>
                        {createUserError ? 
                            (
                                <Alert message={createUserError} type="error" showIcon closable />
                            ) : null
                        }
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Select
                                placeholder='Select title'
                                allowClear
                            >
                                <Option value="mr">Mr</Option>
                                <Option value="mrs">Mrs</Option>
                                <Option value="miss">Miss</Option>
                            </Select>
                        </Form.Item>
                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item 
                                    label="First name"
                                    name="firstName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter firstname"
                                        },
                                        {
                                            min: 3,
                                            message: 'Minimum of three characters required!',
                                        }
                                    ]}
                                >
                                <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                        label="Last name"
                                        name="lastName"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter lastname"
                                            },
                                            {
                                                min: 3,
                                                message: 'Minimum of three characters required!',
                                            }
                                        ]}
                                    >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <Select
                                placeholder='Select gender'
                                allowClear
                                >
                                <Option value="male">Male</Option>
                                <Option value="female">Female</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item 
                            label="Email address"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter email"
                                },
                                {
                                    type:"email",
                                    message:"Please enter a valid email"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item 
                            label="Phone Number"
                            name="phone"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter phone number"
                                }
                            ]}
                        >
                            <InputNumber min={10} style={{width: '100%'}} />
                        </Form.Item>
                        <Form.Item
                            label='Profession'
                            name="profession"
                            rules={[
                                {
                                  required: true,
                                  message: 'Please enter profession',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Area of Expertise"
                            name="areaOfExpertise"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter area of expertise"
                                }
                            ]}
                        >
                             <Select
                                placeholder="Select an area of expertise"
                                allowClear
                            >
                                {renderFeats()}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Years of Experience"
                            name="yearsOfExperience"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Career Brief"
                            name="bio"
                            rules={[
                                {
                                  max: 950,
                                  message: 'maximum character length of 950 reached'
                                }
                            ]}
                        >
                            <Input.TextArea rows={4} maxLength={950} />
                        </Form.Item>
                        <Form.Item>
                            <Button 
                                htmlType="submit" 
                                disabled={createUserState.isLoading === true} 
                                id='createUser' 
                                shape='round' 
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </ModalBody>
                </Form>
            </Modal>
        </>
    )
}

export default CreateUserForm;

