import './index.css';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal, 
    ModalBody, 
    ModalHeader,
} from 'reactstrap';
import {
    Alert, 
    Form, 
    Input, 
    Button,
    message,
    Select, 
    Upload
} from 'antd';

import { createUser, createUserCleanup } from '../../store/actions/create-user';
import { fetchAdmins } from '../../store/actions/fetchAdmins';


//SVGS
import { ReactComponent as Camera } from '../../svg/Camera.svg';



const CreateAdminForm = ({createAdminModal, createAdminToggleModal}) => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const dispatch = useDispatch();
    const [createUserError, setCreateUserError] = useState();
    const createUserState = useSelector( state => state.createUser);
    
    

    const onFinish = values => {
        if(createUserError){
            setCreateUserError(null);
        }
     
        const adminData = {
            ...values,
            email: values.email.toLowerCase(),
            role: 'admin'
        }
        dispatch(createUser(adminData));
    }

    useEffect(() => {
        if (createUserState.isSuccessful) {
          dispatch(createUserCleanup());
          form.resetFields();
          message.success("User successfully created!");
          createAdminToggleModal()
          dispatch(fetchAdmins())
        }
        else if (createUserState.error) {
            setCreateUserError(createUserState.error);
            dispatch(createUserCleanup());
        }
    }, [createUserState]);
    


    return (
        <>
            <Modal isOpen={createAdminModal} toggle={createAdminToggleModal} className='createAdminForm'>
                <Form 
                    form={form}
                    layout='vertical'
                    colon={false} 
                    onFinish={onFinish} 
                >
                    <ModalHeader toggle={createAdminToggleModal}>
                        <Form.Item
                            name="upload"
                            valuePropName="fileList"
                        >
                            <Upload name="logo" action="/upload.do" listType="picture">
                                <Button icon={<Camera />}></Button>
                            </Upload>
                        </Form.Item>
                    </ModalHeader>
                    <ModalBody>
                        <h4 className='mmc-text-primary account-info'>Account Information</h4>
                        {
                            createUserError ? 
                            (
                                <Alert message={createUserError} type="error" showIcon closable />
                            ) : null
                        }
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Select
                                placeholder='Select title'
                                allowClear
                                >
                                <Option value="mr">Mr</Option>
                                <Option value="mrs">Mrs</Option>
                                <Option value="miss">Miss</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item 
                            label="First Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter firstname"
                                },
                                {
                                    min: 3,
                                    message: 'Minimum of three characters required!',
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item 
                            label="Last Name"
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter lastname"
                                },
                                {
                                    min: 3,
                                    message: 'Minimum of three characters required!',
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item 
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter email"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <Select
                                placeholder='Select gender'
                                allowClear
                                >
                                <Option value="male">Male</Option>
                                <Option value="female">Female</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item 
                            label="Phone Number"
                            name="phone"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter phone number"
                                }
                            ]}
                        >
                            <Input min={10} style={{width: '100%'}} />
                        </Form.Item>
                        <Form.Item>
                            <Button 
                                htmlType="submit" 
                                disabled={createUserState.isLoading === true}
                                id='createAdmin' 
                                shape='round' 
                            >
                                Add Admin
                            </Button>
                        </Form.Item>
                    </ModalBody>
                </Form>
            </Modal>
        </>
    )
}
export default CreateAdminForm;

